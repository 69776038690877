

// 1.basic
// 2.HEADER
// 3.hero
// 4.features
// 5.call action
// 6.footer
/*===========================
    1.basic  
===========================*/
li {
  list-style: none;
}
.canvas_close svg{
  margin-bottom: 5px;
}
.ms-05 {
  margin-left:.5rem;
}
.ptb--30{
  padding-top: 30px;
  padding-bottom: 30px;
}
.plr--50{
  padding-left: 50px;
  padding-right: 50px;
}

.pt-90 {
	padding-top: 90px;
}

.pb-100 {
	padding-bottom: 100px;
}
.pt-60 {
	padding-top: 60px;
}
.pb-60 {
	padding-bottom: 60px;
}
.appie-section-title {
  padding-bottom: 25px;
}
.appie-section-title .appie-title {
  font-size: 44px;
  line-height: 54px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-section-title .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-section-title .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 767px) {
  .appie-section-title .appie-title {
    font-size: 26px;
    line-height: 36px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .appie-section-title .appie-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.appie-section-title p {
  font-size: 18px;
  line-height: 28px;
  margin-top: 11px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-section-title p {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-section-title p {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .appie-section-title p {
    font-size: 15px;
  }
}
.appie-section-title .main-btn {
  border-radius: 30px;
  border: 2px solid #e7eaef;
  background: #fff;
  color: #0e1133;
}
.appie-section-title .main-btn:hover {
  background: #ff3e66;
  color: #fff;
  border-color: #ff3e66;
}
.appie-section-title.appie-section-title-2 .appie-title {
  color: #fff;
}
.appie-section-title.appie-section-title-2 p {
  color: #fff;
}


@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1200px !important;
  }
}
.container {
  max-width: 1200px !important;
}
.bg-light-1{
  background-color: #fcfcfc;
}
.sec_pad {
  padding: 120px 0px;
}

/*===========================
    1.animatio css 
===========================*/

@keyframes animationFramesOne {
  0% {
      transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
      transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
      transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
      transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
      transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
      transform: translate(0px, 0px) rotate(0deg);
  }
}


@keyframes animationFramesFive {
  0% {
    transform: translate(-40px, 0px) rotate(0deg);
  }

 

  25% {
      transform: translate(50px, -200px) rotate(108deg);
  } 
  50% {
    transform: translate(-5px, -400px) rotate(108deg);
}
  75% {
    transform: translate(50px, -200px) rotate(108deg);
}

  100% {
    transform: translate(-40px, 0px) rotate(0deg);
  }
}


@-webkit-keyframes animationFramesFive {
  0% {
      -webkit-transform: translate(61px, -99px) rotate(0deg);
  }

  21% {
      -webkit-transform: translate(4px, -190px) rotate(38deg);
  }

  41% {
      -webkit-transform: translate(-139px, -200px) rotate(74deg);
  }

  60% {
      -webkit-transform: translate(-263px, -164px) rotate(108deg);
  }

  80% {
      -webkit-transform: translate(-195px, -49px) rotate(144deg);
  }

  100% {
      -webkit-transform: translate(-1px, 0px) rotate(180deg);
  }
}


@-webkit-keyframes animationFramesOne {
  0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
      -webkit-transform: translate(73px, -1px) rotate(36deg);
  }

  40% {
      -webkit-transform: translate(141px, 72px) rotate(72deg);
  }

  60% {
      -webkit-transform: translate(83px, 122px) rotate(108deg);
  }

  80% {
      -webkit-transform: translate(-40px, 72px) rotate(144deg);
  }

  100% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
}


@keyframes animationFramesThree {
  0% {
    -webkit-transform: translate(6px, -179px);
  }
  50% {
    -webkit-transform: translate(-10px, 10px);
  }  
  100% {
   -webkit-transform: translate(6px, -179px);
  }
}



@-webkit-keyframes animationFramesThree {
  0% {
    -webkit-transform: translate(165px, -179px);
  }
  50% {
    -webkit-transform: translate(-10px, 10px);
  }  
  100% {
   -webkit-transform: translate(165px, -179px);
  }
}

/*===========================
    2.HEADER css 
===========================*/
.ak-sticky.sticky {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1010;
    background: #fff;
    box-shadow: 0 15px 30px 0 #0000000f;
    padding-top: 20px;
    padding-bottom: 20px;
    animation: sticky 1.2s;
  }
  
  .header-area {
    padding-top: 30px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
  }
  .btn-box{
    text-align: left;
  }
.header-area.header-2-area .btn-box .main-btn {
    background: #4286f4;
    border-radius: 30px;
    border-color: #4286f4;
    
  }
  .header-area.header-2-area .btn-box .main-btn:hover {
    background: transparent;
    color: #4286f4;
  }
  .header-area.header-3-area.ak-sticky.sticky {
    background: #0e1133;
  }
  .header-area.header-4-area.ak-sticky.sticky {
    background: linear-gradient(90deg, #6b1fcd 0%, #374ede 100%);
  }
  .header-area.header-page-area.ak-sticky.sticky {
    background: #0a44b9;
  }
  .header-area.header-error-page {
    box-shadow: 0px 10px 20px 0px rgba(14, 17, 51, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .header-nav-box .btn-box {
    position: relative;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-nav-box .btn-box {
      padding-right: 50px;
    }
  }
  @media (max-width: 767px) {
    .header-nav-box .btn-box {
      padding-right: 30px;
    }
  }
  .header-nav-box .btn-box .login-btn {
    color: #0e1133;
    font-size: 15px;
    font-weight: 500;
  }
  .header-nav-box .btn-box .login-btn i {
    padding-right: 6px;
  }
  @media (max-width: 767px) {
    .header-nav-box .btn-box .main-btn {
      display: none;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-nav-box .btn-box .main-btn {
      padding: 0 10px;
      display: inline-block;
    }
  }


  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-main-menu {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .header-main-menu {
      display: none;
    }
  }
  .header-main-menu ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .header-main-menu ul > li {
    display: inline-block;
    margin-right: 30px;
    position: relative;
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .header-main-menu ul > li {
      margin-right: 14px;
    }
  }

  .header-main-menu ul > li > a i {
    padding-left: 6px;
  }
  .header-main-menu ul > li .sub-menu {
    position: absolute;
    left: 0;
    top: 110%;
    width: auto;
    min-width: 220px;
    max-width: 220px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all linear 0.3s;
    z-index: 99;
    -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
    list-style-type: none;
    margin: 0;
    padding: 15px 0;
    border-radius: 5px;
    text-align: left;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .header-main-menu ul > li .sub-menu {
      min-width: 200px;
      max-width: 200px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .header-main-menu ul > li .sub-menu {
      min-width: 200px;
      max-width: 200px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-main-menu ul > li .sub-menu {
      position: relative;
      width: 100%;
      left: 0;
      top: auto;
      opacity: 1;
      visibility: visible;
      display: none;
      right: auto;
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-transition: all none ease-out 0s;
      -moz-transition: all none ease-out 0s;
      -ms-transition: all none ease-out 0s;
      -o-transition: all none ease-out 0s;
      transition: all none ease-out 0s;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      text-align: left;
      border-top: 0;
      transition: 0s;
      padding: 0;
    }
  }
  @media (max-width: 767px) {
    .header-main-menu ul > li .sub-menu {
      position: relative;
      width: 100%;
      left: 0;
      top: auto;
      opacity: 1;
      visibility: visible;
      display: none;
      right: auto;
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-transition: all none ease-out 0s;
      -moz-transition: all none ease-out 0s;
      -ms-transition: all none ease-out 0s;
      -o-transition: all none ease-out 0s;
      transition: all none ease-out 0s;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      text-align: left;
      border-top: 0;
      transition: 0s;
      padding: 0;
    }
  }
  .header-main-menu ul > li .sub-menu > li {
    position: relative;
    margin-left: 0;
    display: block;
  }
  .header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-main-menu ul > li .sub-menu > li .sub-menu {
      margin-left: 0;
    }
  }
  @media (max-width: 767px) {
    .header-main-menu ul > li .sub-menu > li .sub-menu {
      margin-left: 0;
    }
  }
  .header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
    color: #0e1133;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .header-main-menu ul > li .sub-menu > li a {
    display: block;
    padding: 0px 30px;
    position: relative;
    color: #505056;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 4px;
    margin: 0 0;
    line-height: 2.5;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .header-main-menu ul > li .sub-menu > li a {
      padding: 0 20px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .header-main-menu ul > li .sub-menu > li a {
      padding: 0 20px;
    }
  }
  .header-main-menu ul > li .sub-menu > li a i {
    float: right;
    font-size: 16px;
    margin-top: 10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-main-menu ul > li .sub-menu > li a i {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .header-main-menu ul > li .sub-menu > li a i {
      display: none;
    }
  }
  .header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
    display: inline-block;
  }
  .header-main-menu ul > li .sub-menu > li a:hover {
    padding-left: 35px;
  }
  .header-main-menu ul > li .sub-menu > li .sub-menu {
    right: auto;
    left: 100%;
    top: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-main-menu ul > li .sub-menu > li .sub-menu {
      padding-left: 30px;
      -webkit-transition: all 0s ease-out 0s;
      -moz-transition: all 0s ease-out 0s;
      -ms-transition: all 0s ease-out 0s;
      -o-transition: all 0s ease-out 0s;
      transition: all 0s ease-out 0s;
    }
  }
  @media (max-width: 767px) {
    .header-main-menu ul > li .sub-menu > li .sub-menu {
      padding-left: 30px;
      -webkit-transition: all 0s ease-out 0s;
      -moz-transition: all 0s ease-out 0s;
      -ms-transition: all 0s ease-out 0s;
      -o-transition: all 0s ease-out 0s;
      transition: all 0s ease-out 0s;
    }
  }
  .header-main-menu ul > li .sub-menu > li .sub-menu li {
    position: relative;
  }
  .header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
    right: auto;
    left: 100%;
    top: 50%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
    top: 0%;
    opacity: 1;
    visibility: visible;
  }
  .header-main-menu ul > li .sub-menu > li:hover .sub-menu {
    top: 0%;
    opacity: 1;
    visibility: visible;
  }
  .header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
    color: #2b70fa;
  }
  .header-main-menu ul > li .sub-menu > li:hover > a {
    color: #2b70fa;
  }
  .header-main-menu ul > li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }
  
  @-webkit-keyframes sticky {
    0% {
      top: -200px;
    }
    100% {
      top: 0;
    }
  }
  @keyframes sticky {
    0% {
      top: -200px;
    }
    100% {
      top: 0;
    }
  }

  
  /*==== rtl css ====*/

.header-area-rtl .header-main-menu ul{
	text-align: right;
}
  /*==== hover nav ====*/
  .header-main-menu ul>li>a {
    font-size: 15px;
    font-weight: 500;
    color: #0e1133;
    line-height: 45px;
  }
  
.header-area-rtl .header-main-menu ul > li .sub-menu{
	text-align: right;
}
.header-nav-box.header-nav-box-5 .btn-box .login-btn {
  color: #fff;
}

.header-nav-box.header-nav-box-5 .header-main-menu ul>li>a {
  position: relative;
  color: #222;

}
.header-nav-box.header-nav-box-5 .header-main-menu ul li a:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #4286f4;
  height: 3px;
  transition: all .5s;

}
.header-nav-box.header-nav-box-5 .header-main-menu ul li a:hover:after {
  width: 100%;
}
.header-nav-box.header-nav-box-5 .btn-box .main-btn {
  background: transparent;
  border-color: white;
  color: white;
}
.header-nav-box.header-nav-box-5 .btn-box .main-btn:hover {
  background: white;
  color: #05908d;
}

.header-nav-box.header-nav-box-5 .toggle-btn {
  color: #222;
  font-size: 20px;
}
/*===========================
    2.Drawer  
===========================*/

.off_canvars_overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9998;
  opacity: 0;
  visibility: hidden;
  cursor: crosshair;
  background: #232323;
  top: 0;
  transition: all linear 0.3s;
}

.off_canvars_overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.offcanvas_menu {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas_menu {
    display: block;
  }
}

@media (max-width: 767px) {
  .offcanvas_menu {
    display: block;
  }
}

.offcanvas_menu_wrapper {
  width: 290px;
  position: fixed;
  background: #fff;
  z-index: 9999;
  top: 0;
  height: 100%;
  transition: 0.5s;
  left: 0;
  margin-left: -300px;
  padding: 50px 15px 30px;
  overflow-y: visible;
}

.offcanvas_menu_wrapper.active {
  margin-left: 0;
}

.offcanvas_menu_wrapper .slinky-theme-default {
  background: inherit;
  min-height: 300px;
  overflow-y: auto;
}

.offcanvas_menu_wrapper .header-btn {
  margin-bottom: 30px;
}

.offcanvas_menu_wrapper .header-btn a {
  color: #222;
}

.offcanvas_menu_wrapper .header-btn a:hover {
  color: #222;
}

.offcanvas_main_menu li {
  position: relative;
}

.offcanvas_main_menu li:last-child {
  margin: 0;
}

.offcanvas_main_menu li span.menu-expand {
  position: absolute;
  right: 0;
}

.offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #222 !important;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.offcanvas_main_menu li a:hover {
  color: #2b70fa;
}

.offcanvas_main_menu li ul.sub-menu {
  padding-left: 20px;
}

.offcanvas_footer {
  padding-bottom: 50px;
  text-align: center;
}

.offcanvas_footer span a {
  font-size: 14px;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.offcanvas_footer span a:hover {
  color: #2b70fa;
}

.offcanvas_menu_wrapper.active .canvas_close {
  opacity: 1;
  visibility: visible;
}

.canvas_close {
  position: absolute;
  top: 10px;
  right: -17px;
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
}

.canvas_close a {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
  border: 1px solid #2b70fa;
  border-radius: 10px;
  background: #2b70fa;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.canvas_close a:hover {
  background: #2b70fa;
  border-color: #2b70fa;
  color: #222;
}

.canvas_open a {
  font-size: 26px;
  width: 50px;
  height: 42px;
  display: block;
  line-height: 39px;
  text-align: center;
  border: 1px solid #232323;
  color: #222;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.canvas_open a:hover {
  color: #2b70fa;
  border-color: #2b70fa;
}

.offcanvas-social {
  margin-bottom: 40px;
  margin-top: 40px;
}

.offcanvas-social ul li {
  display: inline-block;
}

.offcanvas-social ul li a {
  font-size: 18px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  color: #2b70fa;
  border-radius: 50%;
  margin: 0 12px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.offcanvas-social ul li a:hover {
  background: #2b70fa;
  border-color: #2b70fa;
  color: #fff;
}

.toggle-btn {
  position: absolute;
  left: 160px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #0e1133;
}

@media only screen and (max-width: 575px){
  .toggle-btn{
    left:22px;
  }
}
.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(./../../../assets/images/preloader.svg);
}

.preloader-close {
  position: fixed;
  z-index: 999999;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  right: 40px;
  bottom: 40px;
  font-weight: 600;
  line-height: 60px;
  background: #2b70fa;
  padding: 0 15px;
  border-radius: 5px;
}

.loader-wrap {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.loader-wrap .layer-one {
  position: absolute;
  left: 0%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-two {
  position: absolute;
  left: 33.3333%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer-three {
  position: absolute;
  left: 66.6666%;
  top: 0;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
}

.loader-wrap .layer .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f4f9ff;
}

.footer-widget-info ul li a{
  color: #222;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
/*===========================
    3.HERO  
===========================*/
.hero-area {
  padding-top: 200px;
  padding-bottom: 110px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.hero-area.hero-3-area .hero-content .title {
  color: #fff;
  font-size: 70px;
  line-height: 80px;
  padding: 0 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area .hero-content .title {
    font-size: 54px;
    line-height: 64px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-3-area .hero-content .title {
    font-size: 50px;
    line-height: 60px;
    padding: 0 100px;
    line-height: 1.5;
  }
}
@media (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .title {
    font-size: 36px;
    line-height: 46px;
    padding: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .title {
    font-size: 46px;
    line-height: 56px;
    padding: 0;
  }
}
.hero-area.hero-3-area .hero-content p {
  color: #fff;
  opacity: 0.5;
  font-size: 18px;
  line-height: 28px;
}
.hero-area.hero-3-area .hero-content .hero-btns {
  margin-top: 35px;
}
.hero-area.hero-3-area .hero-content .hero-btns .main-btn {
  border-color: #ff6b58;
  color: #ff6b58;
  background: transparent;
  margin-right: 14px;
}
.hero-area.hero-3-area
  .hero-content
  .hero-btns
  .video-popup {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .hero-area.hero-3-area
    .hero-content
    .hero-btns
    .video-popup {
    margin-top: 20px;
  }
}
.hero-area.hero-3-area
  .hero-content
  .hero-btns
  .video-popup
  i {
  height: 45px;
  width: 45px;
  text-align: center;
  line-height: 42px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  margin-right: 10px;
}
.hero-area.hero-3-area .hero-content .thumb {
  margin-bottom: -210px;
}
@media (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .thumb {
    display: none;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area .hero-content .thumb {
    display: block;
    margin-bottom: -210px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area.hero-5-area {
    padding-top: 120px;
    padding-bottom: 0px;
  }
}
.hero-area.hero-3-area.hero-5-area
  .hero-content
  .thumb {
  margin-bottom: -110px;
}
.hero-area.hero-3-area.hero-5-area
  .hero-content
  .title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-3-area.hero-5-area
    .hero-content
    .title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-3-area.hero-5-area
    .hero-content
    .title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .hero-area.hero-3-area.hero-5-area
    .hero-content
    .title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-area.hero-3-area.hero-5-area
    .hero-content
    .title {
    font-size: 36px;
    line-height: 46px;
  }
}
.hero-area.hero-3-area.hero-5-area
  .hero-content
  .hero-btns
  .video-popup
  i {
  border-color: rgba(255, 255, 255, 0.2);
}
.hero-area.hero-3-area.hero-5-area
  .hero-content
  .hero-btns
  .main-btn {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.hero-area.hero-3-area.hero-5-area
  .hero-content
  .hero-btns
  .main-btn:hover {
  background: #f84a6e;
  color: #fff;
}
.hero-area .hero-shape-1 {
  position: absolute;
  left: 0;
  top: 250px;
  z-index: -1;
  animation: linear 20s animationFramesOne infinite;
  filter: drop-shadow(0px 0px 40px #4286f4);
}
.hero-area .hero-shape-2 {
  position: absolute;
  left: 80px;
  z-index: 11;
  top: 480px;
  animation: linear 25s animationFramesThree infinite;
  filter: drop-shadow(0px 0px 30px #ff9f43);
}
@media only screen and  (max-width: 991px){
  .hero-area .hero-shape-2 {
    left: 303px;
    top: 717px;
  }
}

.hero-area .hero-shape-3 {
  position: absolute;
  bottom: 211px;
  right: 626px;
  z-index: 11;
  animation: linear 25s animationFramesFive infinite;
  filter: drop-shadow(0px 0px 30px #4286f4);
}

@media only screen and(max-width: 991px){
  .hero-area .hero-shape-3 {
    bottom: 94px;
    right: 329px;
  }
}
.hero-area.hero-8-area .home-8-shape-1 {
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: -1;
}
.hero-area.hero-8-area .home-8-shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.hero-area.hero-8-area .home-8-shape-3 {
  position: absolute;
  top: 400px;
  right: 290px;
  z-index: -1;
}
.hero-area.hero-8-area .home-8-shape-4 {
  position: absolute;
  bottom: 195px;
  left: 195px;
  z-index: -1;
}
.hero-area.hero-8-area .hero-content-8 .title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
  background: linear-gradient(90deg, #fb2d4e 25%, #4e88ff 75%);
  -webkit-text-fill-color: transparent;
  // -webkit-background-clip: text;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-area.hero-8-area .hero-content-8 .title {
    font-size: 56px;
    line-height: 66px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area.hero-8-area .hero-content-8 .title {
    font-size: 42px;
    line-height: 66px;
  }
}
@media (max-width: 767px) {
  .hero-area.hero-8-area .hero-content-8 .title {
    font-size: 32px;
    line-height: 44px;
  }
}

.hero-thumb-6 .thumb {
  position: relative;
  z-index: 10;
}
.hero-thumb-6 .thumb .back-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: -1;
}

@media only screen and  (max-width: 991px) {
  .hero__image{
     width:350px
  }
}
@media only screen and  (max-width: 576px) {
  .hero__image{
     width:300px
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content {
    margin-bottom: 50px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .hero-content {
    margin-bottom: 50px;
    text-align: center;
  }
}
.hero-content > span {
  color: #2b70fa;
  font-size: 14px;
  font-weight: 500;
}
.hero-content .title {
  color:#222;
  font-size: 60px;
  line-height: 70px;
  margin-top: 10px;
  margin-bottom: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content .title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content .title {
    font-size: 48px;
    line-height: 80px;
    padding: 0 80px;
  }
}
@media (max-width: 767px) {
  .hero-content .title {
    font-size: 32px;
    line-height: 45px;
    padding: 0 10px;

  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content .title {
    font-size: 36px;
    line-height: 46px;
  }
}
.hero-content p {
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 767px) {
  .hero-content p {
    font-size: 15px;
    line-height: 26px;
  }
}
.hero-content ul {
  margin: 41px 0 0;
  padding: 0;
  list-style-type: none;
}
.hero-content ul li {
  display: inline-block;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .hero-content ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content ul li {
    margin-bottom: 0px;
  }
}
.hero-content ul li a {
  background: #0e1133;
  color: #fff;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 2px solid #0e1133;
}
.hero-content ul li a i {
  padding-right: 6px;
}
.hero-content ul li a:hover {
  background: transparent;
  color: #0e1133;
}
.hero-content ul li a.item-2 {
  background: transparent;
  color: #0e1133;
}
.hero-content ul li a.item-2:hover {
  background: #0e1133;
  color: #fff;
}
.hero-content.hero-content-4 {
  padding-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content.hero-content-4 {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .hero-content.hero-content-4 {
    padding-right: 0;
  }
}
.hero-content.hero-content-4 > span {
  color: #801f82;
}
.hero-content.hero-content-4 .title {
  font-size: 70px;
  font-weight: 700;
  line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content.hero-content-4 .title {
    font-size: 60px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content.hero-content-4 .title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .hero-content.hero-content-4 .title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content.hero-content-4 .title {
    font-size: 40px;
    line-height: 50px;
  }
}
.hero-content.hero-content-4 a.main-btn {
  border: 2px solid #801f82;
  color: #801f82;
  background: #fff;
  line-height: 55px;
  padding: 0 40px;
  font-size: 16px;
  margin-top: 40px;
}
.hero-content.hero-content-4 a.main-btn:hover {
  background: #801f82;
  color: #fff;
}
.hero-content.hero-content-6 > span {
  color: #9b2cfa;
}
.hero-content.hero-content-6 .title {
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-content.hero-content-6 .title {
    font-size: 60px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content.hero-content-6 .title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .hero-content.hero-content-6 .title {
    font-size: 36px;
    line-height: 46px;
  }
}
.hero-content.hero-content-6 ul li a {
  background: #9b2cfa;
  border-color: #9b2cfa;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
.hero-content.hero-content-6 ul li a span {
  line-height: 20px;
}
.hero-content.hero-content-6 ul li a span span {
  font-size: 16px;
  font-weight: 700;
  display: block;
  line-height: 20px;
}
.hero-content.hero-content-6 ul li a i {
  font-size: 30px;
}
.hero-content.hero-content-6 ul li a:hover {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}
.hero-content.hero-content-6 ul li a.item-2 {
  background: #fff;
  border-color: #fff;
}
.hero-content.hero-content-6 ul li a.item-2:hover {
  background: #9b2cfa;
  border-color: #9b2cfa;
  color: #fff;
}
.hero-content.hero-content-6.hero-content-7 .title {
  color: #fff;
}
.hero-content.hero-content-6.hero-content-7 p {
  color: #fff;
  opacity: 0.5;
}
.hero-content.hero-content-6.hero-content-7 ul li a {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}
.hero-content.hero-content-6.hero-content-7 ul li a:hover {
  background: transparent;
  border-color: #54386a;
  color: #fff;
}
.hero-content.hero-content-6.hero-content-7 ul li a.item-2 {
  background: transparent;
  color: #fff;
  border-color: #54386a;
}
.hero-content.hero-content-6.hero-content-7
  ul
  li
  a.item-2:hover {
  border-color: #fff;
  background: #fff;
  color: #0e1133;
}

.hero-thumb {
  position: relative;
  z-index: 10;
  text-align: center;
}
.hero-thumb .thumb-2 {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 30px 70px 0px rgba(68, 1, 15, 0.2);
}
.hero-thumb::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 500px;
  width: 500px;
  transform: translate(-50%, -50%);
  background: #4286f4;
  border-radius: 50%;
  z-index: -1;
}

@media only screen and  (max-width: 991px){

  .hero-thumb::before {
    width:350px;
    height:350px;
  }
}

@media only screen and  (max-width: 576px){

  .hero-thumb::before {
    width:300px;
    height:300px;
  }
}


/*============= 4.features  ===========*/
.features_title {
  margin-bottom: 75px;
}
.features_title h2 {
  color: #222;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
}
@media (max-width: 767px){
  .features_title h2{
    font-size: 30px;
  }
}
.features_title h2 span {
  font-weight: 700;
}
.features_title p {
  max-width: 730px;
  margin: 0px auto;
  color: #677294;
}

.features_features_item {
  text-align: center;
  padding: 0px 30px;
}
@media only screen and (max-width: 992px) {
  .features_features_item {
    margin-bottom:50px;
  }
}
.features_features_item .round {
  height: 245px;
  line-height: 238px;
  position: relative;
}
.features_features_item .round .top_img {
  top: -6px;
  right: 8px;
  transition: all 0.3s linear;
}
.features_features_item .round_circle {
  background: #19191914;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: -1;
  top: 0;
}

.features_features_item h3 {
  font-weight: 700;
  color: #222;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}
.features_features_item p {
  color: #666666;
  line-height: 24px;
}
.features_features_item:hover .round .top_img {
  transform: rotate(90deg);
}

/*============= features_features_area css ===========*/
.features_features_area {
  padding: 120px 0px;
}

.features_features_img {
  background-image: -moz-linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  background-image: -webkit-linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  background-image: -ms-linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  background-image: linear-gradient(-140deg, #83e0f4 0%, #7094fe 100%);
  width: 100%;
  height: 475px;
  position: relative;
  padding-left: 60px;
}
.features_features_img .features_features_img_bg_one {
  position: absolute;
  left: 0;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
}
.features_features_img .features_one, .features_features_img .features_two {
  box-shadow: 0px 5px 20px 0px rgba(12, 0, 46, 0.15);
}
.features_features_img .features_one {
  top: 0px;
  position: relative;
}
.features_features_img .features_two {
  left: 46%;
  bottom: -35px;
}
.features_features_img .dot_bg {
  left: -30px;
  bottom: 40px;
}
.features_features_content img {
  margin-bottom: 28px;
}
.features_features_content h2 {
  font-size: 36px;
  font-weight: 400;
  color: #222;
  margin-bottom: 16px;
}
.features_features_content h2 span {
  font-weight: 700;
}
.features_features_content p {
  line-height: 30px;
  color: #666666;
}
.features_features_content a {
  color: #666666;
  font-size: 14px;
  font-weight: 700;
}
.features_features_content a:hover {
  color: #6754e2;
}

.features_features_img_two {
  background-image: -moz-linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  background-image: -webkit-linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  background-image: -ms-linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  background-image: linear-gradient(-140deg, #f7b05c 0%, #f08260 100%);
  margin-left: 30px;
}
.features_features_img_two .features_two {
  bottom: 50px;
  left: -30px;
}
.features_features_img_two .features_one {
  top: -30px;
}

.features_features_img_three {
  background-image: -moz-linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
  background-image: -webkit-linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
  background-image: -ms-linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
  background-image: linear-gradient(-140deg, #5cf77d 0%, #70c7fe 100%);
}
.features_features_img_three .features_two {
  left: 60px;
  bottom: 50px;
}

.features_integration_area {
  padding-bottom: 120px;
}
.features_integration_area .border-bottom {
  margin-bottom: 110px;
}




/*===========================
    5.APPIE FEATURES css 
===========================*/
.appie-features-area {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.appie-features-area .features-shape-1 {
  position: absolute;
  left: 560px;
  top: 130px;
  animation: animationFramesOne 20s linear infinite;
  z-index: -1;
}
.appie-features-area .features-shape-2 {
  position: absolute;
  left: 500px;
  bottom: 0px;
  animation: animationFramesThree 20s linear infinite;
  z-index: -1;
}
.appie-features-area .features-shape-3 {
  position: absolute;
  right: 270px;
  bottom: 0;
  animation: animationFramesFive 20s linear infinite;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-tabs-btn .nav {
    flex-direction: row !important;
    justify-content: center;
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .appie-features-tabs-btn .nav {
    flex-direction: row !important;
    justify-content: center;
    margin-bottom: 40px;
  }
}
.appie-features-tabs-btn .nav a {
  border-right: 3px solid #e8eaef;
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  color: #505056;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-tabs-btn .nav a {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .appie-features-tabs-btn .nav a {
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
  }
}
.appie-features-tabs-btn .nav a i {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  background: #f1f2f7;
  color: #63636a;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.appie-features-tabs-btn .nav a.active {
  border-right-color: #2b70fa;
  background: transparent;
  color: #2b70fa;
}
.appie-features-tabs-btn .nav a.active i {
  color: #fff;
  background: #2b70fa;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-content {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .appie-features-content {
    padding-top: 30px;
  }
}
.appie-features-content span {
  font-size: 14px;
  font-weight: 500;
  color: #2b70fa;
  margin-bottom: 8px;
}
.appie-features-content .title {
  font-size: 44px;
  line-height: 54px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-features-content .title {
    font-size: 36px;
    line-height: 44px;
  }
}
@media (max-width: 767px) {
  .appie-features-content .title {
    font-size: 30px;
    line-height: 40px;
  }
}
.appie-features-content p {
  font-size: 18px;
  line-height: 28px;
  padding-bottom: 30px;
}

.appie-features-area-2 {
  position: relative;
  overflow: hidden;
  z-index: 10;
}
.appie-features-area-2 .features-shape-1 {
  position: absolute;
  left: 240px;
  top: 240px;
  z-index: -1;
  animation: linear 20s animationFramesThree infinite;
}
.appie-features-area-2 .features-shape-2 {
  position: absolute;
  right: 190px;
  top: 160px;
  z-index: -1;
  animation: linear 20s animationFramesFive infinite;
}
.appie-features-area-2 .features-shape-3 {
  position: absolute;
  right: 160px;
  bottom: 260px;
  z-index: -1;
  animation: linear 20s animationFramesOne infinite;
}


.appie-features-box-item {
  border: 2px solid #cfcfcf;
  padding: 35px 70px 35px 30px;
    border-radius: 6px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 20px;
 // margin-left: 100px;
 // margin-right: 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-features-box-item {
    padding: 25px 70px 25px 30px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-box-item {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 767px) {
  .appie-features-box-item {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 60px;
  }
}
.appie-features-box-item .title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .appie-features-box-item .title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .appie-features-box-item .title {
    font-size: 20px;
  }
}
.appie-features-box-item .title::before {
  position: absolute;
  content: "";
  right: -46px;
  top: 0;
  height: 30px;
  width: 30px;
  background: #4286f4;
  border: 5px solid #fc2424;
  border-radius: 50%;
}
.appie-features-box-item p {
  font-size: 14px;
  opacity: 0.6;
  color: #fff;
  margin-top: 3px;
}
.appie-features-box-item:hover {
  border-color: #4286f4;
  
}


.appie-features-box-item.appie-features-box-5-item .title {
  color: #0e1133;
}
.appie-features-box-item.appie-features-box-5-item .title::before {
  border-color: rgba(9, 8, 8, 0.21);
}
.appie-features-box-item.appie-features-box-5-item p {
  color: #505056;
  opacity: 1;
  margin-bottom: 0;
}

.features__image{
    max-width:100%;
     position: relative;
     right:30px
}
@media  only screen and (max-width:992px) {
  .features__image {
    right:0
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .appie-features-thumb {
    text-align: center;
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .appie-features-thumb {
    text-align: center;
    margin-top: 50px;
  }
}

.appie-features-6-area .appie-features-tabs-btn .nav a.active {
  color: #9b2cfa;
  border-right-color: #9b2cfa;
}
.appie-features-6-area .appie-features-tabs-btn .nav a.active i {
  background: #9b2cfa;
}
.appie-features-6-area .appie-features-content > span {
  color: #9b2cfa;
}
.appie-features-6-area .appie-features-content a {
  background: #9b2cfa;
  border-color: #9b2cfa;
}
.appie-features-6-area .appie-features-content a:hover {
  background: #fff;
  color: #9b2cfa;
}





/*--------------------------
      5.call action
-----------------------------*/
.call-to-action {
  border-radius: 20px;
  background: linear-gradient(145deg, #4286f4 0%, #6ca2f8 100%);
  position: relative;
  z-index: 2;
  &::before {
    border-radius: 20px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: url(./../../../assets/images/pattern-1.png);
      content: "";
      z-index: -1;
      opacity: 0.05;
  }
  .inner {
      > span {
          color: #fff;
          font-size: 15px;
          text-transform: uppercase;
          display: block;
          margin-bottom: 9px;
      }
      h2 {
          color: #ffffff;
          font-size: 75px;
          font-weight: 900;
          line-height: 90px;
          @media (max-width: 767px) {
              font-size: 47px;
              line-height: 70px;
          }
      }
      a {
          &.rn-button-style--2 {
              margin-top: 30px;
              &:hover {
                  background: #ffffff;
                  border-color: #ffffff;
                  color: #4286f4;
              }
          }
      }
  }
  &.bg_image {
      &::before {
          display: none;
      }
  }
}

/*--------------------
       6.footer
----------------------*/

$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 450px)';
.social-share{
  list-style: none;
  justify-content:center;
}


ul.social-share.rn-lg-size li a {
  text-decoration: none;
  width: 50px;
  height: 50px;
  line-height: 44px;
  font-size: 16px;
}
ul.social-share li {
  margin: 6px;
}
ul.social-share li a {
  width: 40px;
  display: inline-block;
  height: 40px;
  border: 2px solid rgba(198,201,216,.75);
  line-height: 35px;
  color: rgba(198,201,216,.75);
  border-radius: 100%;
  text-align: center;
  font-size: 14px;
  transition: all linear 0.3s;
}
ul.social-share li:hover a {
  background: #4286f4;
  border-color: #4286f4;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.footer_logo{
  max-width:150px;
}
@media only screen and (max-width: 576px){
  .footer_logo{
    margin:0 auto 10px auto;
  }
}
.footer-style-2{
	background-color: #191919 !important;
  }

/* Footer Style Two  */
.footer-wrapper {
  position: relative;
}



.footer-style-2 {
  p {
      color: #c6c9d8;
      font-size: 14px;
      opacity: 0.75;
  }
}

.copyright-text {
  position: absolute;
  bottom: -114px;

   @media #{$sm-layout} {
       position: static;
       bottom: 0;
       padding-top: 33px;
   }

   @media #{$md-layout} {
       position: static;
       bottom: 0;
       padding-top: 33px;
  }
  p {
      color: #c6c9d8;
      font-size: 14px;
      opacity: 0.75;
  }
}